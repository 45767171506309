import css from './Infrastructure.module.scss'
import global from '../../../styles/Global.module.scss'
import Link from "next/link"
import {useRef} from "react";
import useVisible from "../../../hooks/useVisible";
import {SelectImage} from "../../../actions/util";
import Image from "next/image";

const Infrastructure = ({title, name, text, url, image}) => {
    //animation
    const ref = useRef(null)
    const {isVisible} = useVisible(ref)
    //

    const img_data = SelectImage("/files/images/main/main-infr.jpg", image)

    return (
        <section className={`${css.infrastructure} ${global.section}`} ref={ref}>

            <div className={isVisible ? `${css.pics}` : `${css.pics} ${css.start}`} >
                <Image
                  src={img_data}
                  className={css.map}
                  alt={""}
                  loading={'lazy'}
                  width={'748'}
                  height={'659'}
                />
            </div>

            <div className={isVisible ? `${css.content}` : `${css.content} ${css.start}`}>
                <h2 className='main-page__title main-page__infrastructure' dangerouslySetInnerHTML={{__html:title}}></h2>
                <div dangerouslySetInnerHTML={{__html:text}}></div>
                <Link href={`${url}`}><a className={`${css.button} btn`}>{name}</a></Link>
            </div>

        </section>
    )
}

export default Infrastructure