import { useState, useEffect } from 'react'

export default function useVisible(ref, percent = 0.85) {

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if ('IntersectionObserver' in window) {
            var options = {
                root: null,
                rootMargin: '0px',
                threshold: 1 - (percent || 0)
            }
            var callback = function (entries, observer) {
                if (entries[0]?.isIntersecting) setIsVisible(true)
            };
            var observer = new IntersectionObserver(callback, options);
            observer.observe(ref?.current);
        }  else {
            setIsVisible(true)
        }
        return () => {
            observer.disconnect()
        }
    }, [])

    return { isVisible }
}